import { DefaultValue, selector } from 'recoil';
import appState from '.';
import { AppState, OverlayConfig } from './types';

export const overlayConfigState = selector<OverlayConfig>({
  key: 'overLayConfigState',
  get: ({ get }) => {
    const { config } = get(appState);
    return config?.overlay || {};
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) return set(appState, newValue);
    return set(appState, (prev: AppState) => ({
      ...prev,
      config: {
        ...prev.config,
        overlay: {
          ...prev.config?.overlay,
          ...newValue,
        },
      },
    }));
  },
});

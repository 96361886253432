import { NowPlayingStyle } from 'components/NowPlaying';
import NowPlayingPage from 'pages/NowPlayingPage';
import RoulettePage from 'pages/Roulette';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import SetlistControllerPage from 'pages/SetlistController';
import SetlistDisplayPage from 'pages/SetlistDisplay';

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="overlay">
          <Route
            path="2"
            element={<NowPlayingPage style={NowPlayingStyle.Minimal} />}
          />
          <Route path="1" element={<NowPlayingPage />} />
          <Route path="setlist">
            <Route path="controller" element={<SetlistControllerPage />} />
            <Route path="display" element={<SetlistDisplayPage />} />
          </Route>
          <Route index element={<NowPlayingPage />} />
        </Route>
        <Route path="/roulette" element={<RoulettePage />} />
        <Route path="/" element={<Layout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

// theme.js
import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  ...config,
  fonts: {
    heading: `"Quicksand", 'Open Sans', sans-serif`,
    body: `"Quicksand", 'Raleway', sans-serif`,
  },
  colors: {
    hana: {
      default: '#f5d4c3',
      100: '#f5d4c3',
      200: '#f5d4c3',
      300: '#f5d4c3',
      400: '#f5d4c3',
      500: '#f5d4c3',
      600: '#f5d4c3',
      700: '#f5d4c3',
    },
  },
  components: {
    Link: {
      variants: {
        'no-decoration': {
          _hover: {
            textDecoration: 'none',
          },
        },
      },
    },
    PopoverContent: {
      baseStyle: {
        _focus: { boxShadow: 'none', outline: 'none' },
        _focusVisible: { boxShadow: 'none', outline: 'none' },
      },
    },
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'none' },
      },
      variants: {
        'hana-ghost': {
          _hover: {
            background: 'rgba(255,255,255,0.25)',
          },
        },
      },
    },
  },
});

export default theme;

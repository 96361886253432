import { atom, AtomEffect } from 'recoil';
import { AppState } from './types';

const ATOM_KEY = 'appState';

const localStorageEffect =
  (key: string): AtomEffect<AppState> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    console.log(savedValue);
    if (savedValue != null) {
      const value = JSON.parse(savedValue);
      console.log(value);
      setSelf(value);
    }

    onSet((newValue, _, isReset) => {
      const { emitter, ...rest } = newValue;
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(rest));
    });
  };

const appState = atom<AppState>({
  key: ATOM_KEY,
  default: {},
  effects: [localStorageEffect(ATOM_KEY)],
});

export default appState;

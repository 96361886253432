import { DeleteIcon } from '@chakra-ui/icons';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  Button,
  Text,
} from '@chakra-ui/react';
import React from 'react';

interface RemoveIconProps {
  name: string;
  handleClick: () => void;
}

const RemoveSongButton: React.FC<RemoveIconProps> = ({ name, handleClick }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const onRemove = () => {
    handleClick();
    close();
  };
  return (
    <Popover
      placement="bottom"
      closeOnBlur={true}
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
    >
      <PopoverTrigger>
        <DeleteIcon role="button" onClick={open} />
      </PopoverTrigger>
      <PopoverContent
        color="#3c5556"
        bg="white"
        _focus={{ outline: 'none', boxShadow: 0 }}
        _focusVisible={{ outline: 'none', boxShadow: 0 }}
      >
        <PopoverHeader pt={4} fontWeight="bold" border="0" textAlign="left">
          Confirmation
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody textAlign="left">
          Remove
          <Text fontWeight="semibold" display="inline" color="blue.200" mx={1}>
            "{name}"
          </Text>
          ?
        </PopoverBody>
        <PopoverFooter
          border="0"
          display="flex"
          alignItems="center"
          justifyContent="end"
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button colorScheme="red" color="white" onClick={onRemove}>
              Remove
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default RemoveSongButton;

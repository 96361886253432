import { Box, Flex } from '@chakra-ui/react';
import Menu from 'components/Menu';
import Songlist from 'pages/Songlist';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Flex h="100vh" gap={4} bg="#f6efe7">
      <Menu />
      <Box w="100%" h="100vh">
        <Routes>
          <Route path="/" element={<Songlist />}>
            <Route index element={<Songlist />} />
            <Route path="songlist" element={<Songlist />} />
          </Route>
        </Routes>
      </Box>
    </Flex>
  );
};

export default Layout;

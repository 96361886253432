import { atom } from 'recoil';
import { SongObject } from './types';

const songState = atom<SongObject[]>({
  key: 'songState',
  default: [
    // {
    //   name: `Boku ga Shinou`,
    //   addedBy: 'thesaria',
    //   vod: '123',
    //   marker: '1233',
    //   timestamp: 123,
    // },
  ],
});

export default songState;

import { Box } from '@chakra-ui/react';
import React from 'react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { useRecoilState } from 'recoil';
import { overlayConfigState } from 'store/app/selector';
import { OverlayConfig } from 'store/app/types';

interface DragableContainerProps {
  children: React.ReactNode;
  elementKey: string;
}

const DraggableContainer: React.FC<DragableContainerProps> = ({
  children,
  elementKey,
}) => {
  const nodeRef = React.useRef(null);
  const [overlayConfig, setOverlayConfig] = useRecoilState(overlayConfigState);
  const config = overlayConfig[elementKey as 'nowPlaying' | 'roulette'];

  const onStopDrag =
    (name: string) => (e: DraggableEvent, data: DraggableData) => {
      const { x, y } = data;
      setOverlayConfig((prev: OverlayConfig | undefined) => {
        return { ...prev, [name]: { position: { x, y } } };
      });
    };
  return (
    <Draggable
      onStop={onStopDrag(elementKey)}
      defaultPosition={config?.position}
      nodeRef={nodeRef}
    >
      <Box display="inline-block" cursor="move" ref={nodeRef}>
        {children}
      </Box>
    </Draggable>
  );
};

export default DraggableContainer;

import { Tr, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { SongObject } from 'store/song/types';
import RemoveSongButton from './RemoveSongButton';

interface SongItemProps extends SongObject {
  emitter?: (event: string, payload: Record<any, any>) => void;
}

const SonglistItem: React.FC<SongItemProps> = ({
  name,
  timestamp,
  addedBy,
  emitter,
}) => {
  const handleClick = () => {
    emitter?.('removeSong', { timestamp });
  };
  return (
    <Tr>
      <Td textAlign="left" color="#3c5556">
        {name}
      </Td>
      <Td textAlign="center" color="#3c5556">
        {format(timestamp, 'EEE do LLLL yyyy HH:mm:ss')}
      </Td>
      <Td textAlign="center" color="#3c5556">
        {addedBy}
      </Td>
      <Td textAlign="right" color="#3c5556">
        {emitter && <RemoveSongButton name={name} handleClick={handleClick} />}
      </Td>
    </Tr>
  );
};

export default SonglistItem;

import { Flex, Box, Wrap, Text } from '@chakra-ui/react';
import SonglistTable from 'components/SonglistTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import songState from 'store/song';

const Songlist = () => {
  const songs = useRecoilValue(songState);
  return (
    <Flex h="100vh" w="100%" gap={4}>
      <Box w="100%" h="100%" overflow="scroll" p={4} pt="16">
        <SonglistTable />
      </Box>
      <Box w="300px" h="100%" bg="#e09879">
        <Wrap p="4" px="8" direction="column">
          <Flex direction="column" align="center" justify="center">
            <Text fontSize="4xl" fontWeight="bold" color="white">
              {songs.length}
            </Text>
            <Text fontSize="lg" color="white">
              Songs
            </Text>
          </Flex>
        </Wrap>
      </Box>
    </Flex>
  );
};

export default Songlist;

import Peer from 'peerjs';
import { useEffect, useState } from 'react';

const usePeer = () => {
  const [peer, setPeer] = useState<Peer>();

  useEffect(() => {
    const setlistPeer = new Peer();
    setPeer(setlistPeer);
  }, []);

  return { peer };
};

export default usePeer;

import { Box, Text } from '@chakra-ui/react';
import DraggableContainer from 'components/DragableContainer';
import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import rouletteState from 'store/roulette';

const rouletteConfig = {
  backgroundColors: ['#f1f7b5', '#a8d1d1', '#b4b4d4', '#fd8a8a'],
  textColors: ['#0b3351'],
  outerBorderColor: '#eeeeee',
  outerBorderWidth: 5,
  innerBorderColor: '#000',
  innerBorderWidth: 5,
  innerRadius: 5,
  radiusLineColor: '#eeeeee',
  radiusLineWidth: 1,
  fontFamily: 'Nunito',
  fontWeight: 'bold',
  fontSize: 20,
  fontStyle: 'normal',
  textDistance: 85,
  spinDuration: 0.3,
  startingOptionIndex: 2,
  perpendicularText: true
};

const Roulette: React.FC = () => {
  const resetState = useResetRecoilState(rouletteState);
  const state = useRecoilValue(rouletteState);
  const [isSpin, setIsSpin] = useState(false);

  React.useEffect(() => {
    if (!state?.userName) return;
    startSpin();
  }, [state?.userName]);

  const startSpin = () => {
    setTimeout(() => {
      setIsSpin(true);
    }, 2000);
  };

  const endSpin = () => {
    setIsSpin(false);
    setTimeout(() => {
      resetState();
    }, 4000);
  };

  if (!state || !state.userName) return <></>;

  return (
    <DraggableContainer elementKey="roulette">
      <Box
        opacity={state ? 1 : 0}
        transform={`scale(${state ? 1 : 0.8})`}
        transition="opacity 0.3s, transform 0.3s"
        display={state ? 'block' : 'none'}
        visibility={state ? 'visible' : 'hidden'}
        className="Roulette"
        position="relative"
        style={{
          textRendering: 'optimizeLegibility',
        }}
      >
        <Box className="Roulette-Header">
          <Wheel
            {...rouletteConfig}
            mustStartSpinning={isSpin}
            prizeNumber={state.winningIndex}
            data={state.wheelData}
            onStopSpinning={endSpin}
          />
        </Box>
      </Box>

      <Box
        mx="auto"
        bg="white"
        p="4"
        rounded="full"
        bottom="10px"
        width="fit-content"
        style={{
          border: '1px solid transparent',
        }}
      >
        <Text fontSize="lg" fontWeight="bold">
          {state?.userName}
        </Text>
      </Box>
    </DraggableContainer>
  );
};

export default Roulette;

import React from 'react';
import DraggableContainer from '../DragableContainer';
import OhanaChanNowPlaying from './styles/087Chan';

import MinimalNowPlaying from './styles/Minimal';

export enum NowPlayingStyle {
  '087Chan',
  'Minimal',
}

export interface NowPlayingProps {
  style?: NowPlayingStyle;
}

const NowPlaying: React.FC<NowPlayingProps> = ({ style }) => {
  let NowPlayingComponent;
  switch (style) {
    case NowPlayingStyle['087Chan']:
      NowPlayingComponent = OhanaChanNowPlaying;
      break;
    case NowPlayingStyle['Minimal']:
      NowPlayingComponent = MinimalNowPlaying;
      break;
    default:
      NowPlayingComponent = OhanaChanNowPlaying;
      break;
  }
  return (
    <DraggableContainer elementKey="nowPlaying">
      <NowPlayingComponent />
    </DraggableContainer>
  );
};

export default NowPlaying;

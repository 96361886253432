import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import AppRouter from './AppRouter';
import useSocket from 'hooks/use-socket';
import { useSetRecoilState } from 'recoil';
import appState from 'store/app';
import { AppState } from 'store/app/types';
import theme from 'theme';

const App = () => {
  const { emitter } = useSocket();
  const setAppState = useSetRecoilState(appState);

  React.useEffect(() => {
    setAppState((prev: AppState) => ({ ...prev, emitter }));
    // setTimeout(() => {
    //   setSongState((prev) => [
    //     ...prev,
    //     {
    //       name: `Boku ga Shinou to Omotta no Wa (僕が死のうと思ったのは ; The Reason why i thought I'd die) by Amazarashi (中島美嘉)`,
    //       addedBy: 'thesaria',
    //       vod: '123',
    //       marker: '1233',
    //       timestamp: 123,
    //     },
    //   ]);
    // }, 2500);
  }, [emitter, setAppState]);

  return (
    <ChakraProvider theme={theme}>
      <AppRouter />
    </ChakraProvider>
  );
};

export default App;

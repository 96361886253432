import { Button, Input, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';

interface SetlistControlProps {
  emitter?: (event: string, payload: Record<any, any>) => void;
}

const SetlistControl: React.FC<SetlistControlProps> = ({ emitter }) => {
  const [code, setCode] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleSubmit = () => {
    if (code.trim()) {
      emitter?.('enteredSetlistCode', { code });
      setCode(''); // Clear the input after sending
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleSubmit();
    }
  };

  return (
    <Stack w="100vw" h="100dvh" justify="center" align="center" bg="red.100">
      <Stack p={8} gap={4} bg="gray.50" borderRadius="md">
        <Input
          type="text"
          value={code}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
          placeholder="Enter your code here"
          borderColor="gray.500"
        />
        <Button onClick={handleSubmit} variant="solid" colorScheme="blue">
          Send Code
        </Button>
      </Stack>
    </Stack>
  );
};

export default SetlistControl;

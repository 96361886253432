import React from 'react';
import SetlistControl from 'components/Setlist/controller';
import { useRecoilValue } from 'recoil';
import appState from 'store/app';

const SetlistControllerPage: React.FC = () => {
  const { emitter } = useRecoilValue(appState);
  return <SetlistControl emitter={emitter} />;
};

export default SetlistControllerPage;

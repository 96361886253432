import { Flex, Box, Image, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import NowPlayingDetail from './Detail';

const animationKeyframes = keyframes`
  0% { transform:  rotate(0);  }
  100% { transform:  rotate(360deg);  }
`;

const animation = `${animationKeyframes} 2.5s infinite`;

const OhanaChanNowPlaying: React.FC = () => {
  return (
    <Flex p={2} gap={4} position="relative" align="flex-end">
      <Box
        w="100%"
        h="60px"
        bg="white"
        display="block"
        position="absolute"
        bottom={0}
        left={0}
        borderRadius="lg"
        boxShadow="lg"
      />
      <Box position="relative">
        <Image
          as={motion.img}
          animation={animation}
          src="https://cdn.discordapp.com/emojis/976434961764728843.webp?size=240&quality=lossless"
          boxSize="80px"
          alt="hana"
          borderRadius="full"
          position="relative"
        />
        <Box position="absolute" w="100%" h="100%" top="0" />
      </Box>
      <NowPlayingDetail />
    </Flex>
  );
};

export default OhanaChanNowPlaying;

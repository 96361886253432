import { useRecoilValue } from 'recoil';
import songState from 'store/song';
import { useEffect, useState } from 'react';
import setlistPeerIdState from '../../store/setlist';
import { SongObject } from 'store/song/types';
import usePeer from 'hooks/use-setlist-peer';
import { DataConnection } from 'peerjs';

const DEFAULT_SKIN = 'simpleBlackB';

const SetlistDisplay: React.FC = () => {
  const songs = useRecoilValue(songState);
  const setlistPeerId = useRecoilValue(setlistPeerIdState);
  const setlistPeer = usePeer();
  const [connection, setConnection] = useState<DataConnection>();

  useEffect(() => {
    if (setlistPeerId === '') {
      return;
    }

    const conn = setlistPeer.peer!.connect(setlistPeerId);
    conn.on('open', function () {
      conn.send(['connect', '接続できました。']);
      conn.send(['skin', DEFAULT_SKIN]);
      setConnection(conn);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setlistPeerId]);

  useEffect(() => {
    updateSetlist(songs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songs]);

  const updateSetlist = (songs: SongObject[]) => {
    if (connection === undefined) {
      return;
    }

    const setlistSongs = formatSetlist(songs);
    connection.send(['sync', setlistSongs]);
  };

  const formatSetlist = (songs: SongObject[]): string[] => {
    // Setlista format is [currentSong, reserveList, setlist]
    // Lists are just the names separated by newlines.
    // Everything has to be URI Encoded
    const songsCopy = songs.slice(0);
    let currentSongName = '';
    if (songsCopy.length > 0) {
      currentSongName = songsCopy.pop()!.name;
    }

    const setlist = songsCopy.map((song) => song.name).join('\n');

    return [encodeURI(currentSongName), encodeURI(''), encodeURI(setlist)];
  };

  return (
    <iframe
      id="myIframe"
      title="Setlist"
      src="https://app.nekosogi.org/setlista2/obs.html"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none',
        overflow: 'hidden',
      }}
    />
  );
};

export default SetlistDisplay;

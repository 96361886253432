import React from 'react';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps {
  children?: React.ReactNode;
  to: string;
  isExternal?: boolean;
  variant?: ChakraLinkProps['variant'];
  color?: string;
}

const Link: React.FC<LinkProps> = ({
  to,
  children,
  isExternal,
  variant,
  color,
}) => {
  if (isExternal) {
    return (
      <ChakraLink href={to} isExternal variant={variant} color={color}>
        {children}
      </ChakraLink>
    );
  }
  return (
    <ChakraLink as={RouterLink} to={to} variant={variant} color={color}>
      {children}
    </ChakraLink>
  );
};

export default Link;

import { Flex, Text, Image, HStack, Center, Stack } from '@chakra-ui/react';
import useNowPlaying from 'hooks/use-now-playing';
import React from 'react';
import Marquee from 'react-fast-marquee';
import ohanaSing from '../../../../assets/images/OhanaSing.png';

const MinimalNowPlaying: React.FC = () => {
  const { songName, singer, marquee, songNameRef, singerNameRef } =
    useNowPlaying(160);
  return (
    <Flex
      p={2}
      gap={4}
      position="relative"
      align="flex-end"
      borderRadius="md"
      border="2px"
      borderColor="hana.700"
      minW="300px"
      h="80px"
      bg="rgba(255, 255, 255, 1)"
    >
      <HStack align="center" gap="1" w="300px">
        <Image src={ohanaSing} boxSize="75px" />
        <Stack>
          <Text
            top="8px"
            letterSpacing="2px"
            fontSize="12px"
            px="2"
            color="pink"
            position="relative"
            zIndex="20"
            fontWeight="bold"
            sx={{
              '-webkit-text-stroke-width': '0.1px',
              '-webkit-text-stroke-color': 'hana.700',
            }}
          >
            Now Playing...
          </Text>
          {marquee ? (
            <Marquee gradient={false} speed={25}>
              <Flex
                w="100%"
                direction="column"
                px="2"
                align="flex-start"
                justify="center"
                pt="0"
              >
                <Text
                  color="brown"
                  fontSize="md"
                  fontWeight="500"
                  ref={songNameRef}
                  sx={{
                    '-webkit-text-stroke-width': '0.5px',
                    '-webkit-text-stroke-color': 'brown',
                  }}
                >
                  {songName}
                </Text>
                {!!singer && (
                  <Text
                    color="brown"
                    fontSize="sm"
                    fontWeight="300"
                    ref={singerNameRef}
                    sx={{
                      '-webkit-text-stroke-width': '0.5px',
                      '-webkit-text-stroke-color': 'brown',
                    }}
                  >
                    {singer}
                  </Text>
                )}
              </Flex>
            </Marquee>
          ) : (
            <Center>
              <Flex w="100%" direction="column" px="2" align="flex-start">
                <Text
                  color="brown"
                  fontWeight="500"
                  ref={songNameRef}
                  sx={{
                    '-webkit-text-stroke-width': '0.5px',
                    '-webkit-text-stroke-color': 'brown',
                  }}
                >
                  {songName}
                </Text>
                {!!singer && (
                  <Text
                    color="brown"
                    fontWeight="300"
                    ref={singerNameRef}
                    sx={{
                      '-webkit-text-stroke-width': '0.5px',
                      '-webkit-text-stroke-color': 'brown',
                    }}
                  >
                    {singer}
                  </Text>
                )}
              </Flex>
            </Center>
          )}
        </Stack>
      </HStack>
    </Flex>
  );
};

export default MinimalNowPlaying;

import { atom } from 'recoil';
import { RouletteState } from './types';

const ATOM_KEY = 'rouletteState';

const rouletteState = atom<RouletteState>({
  key: ATOM_KEY,
  default: undefined,
});

export default rouletteState;

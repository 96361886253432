import { WrapItem } from '@chakra-ui/react';
import React from 'react';

interface MenuItemProps {
  children?: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({ children }) => {
  return <WrapItem w="100%">{children}</WrapItem>;
};

export default MenuItem;

import { Flex } from '@chakra-ui/react';
import React from 'react';

interface NowPlayingDetailContainerProps {
  children: React.ReactNode;
  isSingleLine: boolean;
}

const NowPlayingDetailContainer: React.FC<NowPlayingDetailContainerProps> = ({
  children,
  isSingleLine,
}) => {
  return (
    <Flex
      direction="column"
      align="flex-start"
      justify={isSingleLine ? 'center' : 'flex-end'}
      position="relative"
      h="52px"
      w="250px"
      px={2}
    >
      {children}
    </Flex>
  );
};

export default NowPlayingDetailContainer;

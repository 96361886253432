import { Box } from '@chakra-ui/react';
import React from 'react';
import NowPlaying, { NowPlayingProps } from 'components/NowPlaying';

const NowPlayingPage: React.FC<NowPlayingProps> = ({ style }) => {
  return (
    <Box bg="#00b140" w="100vw" h="100vh" p={8}>
      <NowPlaying style={style} />
    </Box>
  );
};

export default NowPlayingPage;

import { Box, Image, Wrap, Button, Icon } from '@chakra-ui/react';
import React from 'react';
import logo from 'assets/images/logo.png';
import MenuItem from './MenuItem';
import Link from 'components/Link';
import { Music } from '@styled-icons/boxicons-solid/Music';

const Menu = () => {
  return (
    <Box w="300px" h="100%" bg="#e09879">
      <Wrap p="4" px="8" spacingY="4" direction="column">
        <MenuItem>
          <Image src={logo} boxSize="100%" objectFit="cover" mb={4} />
        </MenuItem>
        {/* <MenuItem>
          <Link to="/" variant="no-decoration">
            <Button
              variant="hana-ghost"
              leftIcon={<Icon as={Home} size="lg" />}
            >
              Home
            </Button>
          </Link>
        </MenuItem> */}
        <MenuItem>
          <Link to="/songlist" variant="no-decoration" color="white">
            <Button variant="hana-ghost" leftIcon={<Icon as={Music} />}>
              Songlist
            </Button>
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link to="/vtuber" variant="no-decoration">
            <Button variant="hana-ghost" leftIcon={<Icon as={Ghost} />}>
              Vtuber Studio
            </Button>
          </Link>
        </MenuItem> */}
        {/* <MenuItem>
          <Link to="/overlay" isExternal variant="no-decoration">
            <Button
              variant="hana-ghost"
              leftIcon={<Icon as={ExternalLinkIcon} />}
            >
              Overlay
            </Button>
          </Link> */}
        {/* </MenuItem> */}
      </Wrap>
    </Box>
  );
};

export default Menu;

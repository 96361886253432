import { Box } from '@chakra-ui/react';
import React from 'react';
import Roulette from 'components/Roulette';

const RoulettePage: React.FC = () => {
  return (
    <Box bg="green" w="100vw" h="100vh" p={8}>
      <Roulette />
    </Box>
  );
};

export default RoulettePage;

import { Box, Text } from '@chakra-ui/react';
import useNowPlaying from 'hooks/use-now-playing';
import React from 'react';
import Marquee from 'react-fast-marquee';
import NowPlayingDetailContainer from './Container';

const NowPlayingDetail: React.FC = () => {
  const { songName, singer, marquee, songNameRef, singerNameRef } =
    useNowPlaying(200);

  let NowPlayingDetailBase = (
    <Box>
      <Text
        color="#d2683b"
        fontWeight="bold"
        fontSize="lg"
        ref={songNameRef}
        px={4}
      >
        {songName || ''}
      </Text>
      <Text
        color="#d2683b"
        fontSize="xs"
        fontWeight="semibold"
        px={4}
        ref={singerNameRef}
      >
        {singer}
      </Text>
    </Box>
  );
  if (!singer) {
    NowPlayingDetailBase = (
      <Text
        color="#d2683b"
        fontWeight="bold"
        fontSize="lg"
        ref={songNameRef}
        px={4}
      >
        {songName}
      </Text>
    );
  }
  if (!marquee)
    return (
      <NowPlayingDetailContainer isSingleLine={!singer}>
        {NowPlayingDetailBase}
      </NowPlayingDetailContainer>
    );
  return (
    <NowPlayingDetailContainer isSingleLine={!singer}>
      <Marquee gradient={false} speed={15}>
        {NowPlayingDetailBase}
      </Marquee>
    </NowPlayingDetailContainer>
  );
};

export default NowPlayingDetail;

import React from 'react';
import { useSetRecoilState } from 'recoil';
import { io, Socket } from 'socket.io-client';
import rouletteState from 'store/roulette';
import { RouletteState } from 'store/roulette/types';
import setlistPeerIdState from 'store/setlist/';
import songState from 'store/song';
import { SongObject } from 'store/song/types';

interface ApplicationState {
  songs: SongObject[];
}

const useSocket = () => {
  const [socket, setSocket] = React.useState<Socket>();
  const setRouletteState = useSetRecoilState(rouletteState);
  const setSongState = useSetRecoilState(songState);
  const setSetlistPeerId = useSetRecoilState(setlistPeerIdState);
  React.useEffect(() => {
    const ws = io(process.env.REACT_APP_SOCKET_HOST!);
    setSocket(ws);
    ws.on('connect', () => {
      console.log('socket connected');
      ws.emit('appState');
    });

    ws.on('disconnect', () => {
      console.log('socket disconnected');
    });

    ws.on('newSong', (val: SongObject) => {
      const { name, addedBy, timestamp } = val;
      if (!name || !addedBy || !timestamp) return;
      setSongState((prev) => [...prev, val]);
    });

    ws.on('clearSong', (val: SongObject) => {
      setSongState([]);
    });

    ws.on('appState', (response: ApplicationState) => {
      const { songs } = response;
      setSongState(songs || []);
    });

    ws.on('roulette', (payload: RouletteState) => {
      setRouletteState(payload);
    });

    ws.on('enteredSetlistCode', (payload: { code: string }) => {
      console.log('Got peer id: ', payload);
      setSetlistPeerId(payload.code);
    });

    return () => {
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSocket]);

  const emitter = React.useCallback(
    (event: string, payload: Record<any, any>) => {
      if (!socket) return;
      socket.emit(event, payload);
    },
    [socket]
  );

  return { emitter };
};

export default useSocket;

import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import appState from 'store/app';
import songState from 'store/song';
import SonglistItem from './SonglistItem';

const SonglistTable = () => {
  const { emitter } = useRecoilValue(appState);
  const songs = useRecoilValue(songState);

  return (
    <Table size="md" colorScheme="hana">
      <TableCaption>Collected songs</TableCaption>
      <Thead>
        <Tr>
          <Th textAlign="left">
            <Text fontWeight="extrabold" color="#e09789">
              Name
            </Text>
          </Th>
          <Th textAlign="center">
            <Text fontWeight="extrabold" color="#e09789">
              Time
            </Text>
          </Th>
          <Th textAlign="center">
            <Text fontWeight="extrabold" color="#e09789">
              Add By
            </Text>
          </Th>
          <Th textAlign="right">
            <Text fontWeight="extrabold" color="#e09789">
              Action
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {songs.map((song, index) => (
          <SonglistItem key={index} {...song} emitter={emitter} />
        ))}
      </Tbody>
    </Table>
  );
};

export default SonglistTable;
